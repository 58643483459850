import React, { useEffect, useState } from "react";
import { HashRouter, Route, Redirect, Routes } from "react-router-dom";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { CSpinner } from "@coreui/react";
import axios from "axios";
import allActions from "./actions/allActions";
import cookie from "js-cookie";
import { LinearProgress } from "@mui/material";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));

const App = () => {
  const token = cookie.get("token");
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const [requestedUrl, setRequestedUrl] = useState("");

  useEffect(() => {
    const vaildateUser = async () => {
      // verify a token symmetric
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/auth/user-profile`)
        .then((res) => {
          dispatch(allActions.userActions.setUser(res.data));
        })
        .catch((err) => {
          axios.defaults.headers.common["Authorization"] = ``;
          localStorage.clear();
          cookie.remove("token");
          dispatch(allActions.userActions.logOut());
        });
    };
    if (token) {
      vaildateUser();
    } else {
      localStorage.clear();
    }
  }, [token]);

  useEffect(() => {
    if (!requestedUrl) {
      setRequestedUrl(window.location.hash.substring(1));
    }
  }, []);
  const Loader = () => {
    return (
      <div className={"d-flex justify-content-center align-items-center h-100"}>
        <LinearProgress />
      </div>
    );
  };
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <ToastContainer />
        <Routes>
          <Route
            path="*"
            name="Main"
            element={
              currentUser?.loggedIn === true ? (
                <DefaultLayout />
              ) : token ? (
                <Loader />
              ) : (
                <Login />
              )
            }
          />

          <Route path="/404" element={<Page404 />} />
        </Routes>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
